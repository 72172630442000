import React, { FC, InputHTMLAttributes, ReactNode } from 'react';
import styled from 'styled-components';

export const Input = styled.input`
  display: none;
`;

export const Label = styled.label<Pick<Props, 'rounded'>>`
  position: relative;
  padding: 0 0 0 ${(_) => (_.rounded ? '40px' : '32px')};
  height: 28px;
  display: flex;
  align-items: center;
  ::after {
    position: absolute;
    content: '';
    display: block;
    left: 12px;
    transform: translateX(-50%);
    width: ${(_) => (_.rounded ? '28px' : '20px')};
    height: ${(_) => (_.rounded ? '28px' : '20px')};
    background: #ededed;
    border-radius: ${(_) => (_.rounded ? '50%' : '4px')};
    border: ${(_) => (_.rounded ? 'none' : '1px solid #b4b4b4')};
  }
  ::before {
    position: absolute;
    content: '';
    display: block;
    left: 8px;
    margin-top: -4px;
    width: 5px;
    height: 0px;
    border-right: 2.5px solid #101828;
    border-bottom: 2.5px solid #101828;
    transform: rotate(45deg) translateY(100%);
    opacity: 0;
    z-index: 10;
    transition: all 0.1s ease-in;
  }
`;

export const CheckBoxContainer = styled.div`
  display: flex;
  height: 28px;
  align-items: center;
  input[type='checkbox']:checked + label:before {
    opacity: 1;
    height: 10px;
    transform: rotate(45deg) translateY(0);
  }
`;

type Props = {
  id: string;
  placeholder: ReactNode;
  rounded?: boolean;
} & InputHTMLAttributes<HTMLInputElement>;

export const CheckBox: FC<Props> = ({ id, placeholder, rounded = false, ...props }) => (
  <CheckBoxContainer>
    <Input type="checkbox" id={id} {...props} />
    <Label rounded={rounded} htmlFor={id}>
      {placeholder}
    </Label>
  </CheckBoxContainer>
);
