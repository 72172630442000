import styled, { CSSObject } from 'styled-components';

type Props = {
  height?: CSSObject['height'];
  width?: CSSObject['width'];
  maxWidth?: CSSObject['maxWidth'];
};

export const Center = styled.div<Props>((_) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: _.width ?? '100%',
  height: _.height ?? '100%',
  maxWidth: _.maxWidth,
}));
