import styled, { CSSObject } from 'styled-components';

type Props = {
  bg?: 'orange' | 'white' | 'gray' | 'gray-second' | 'skin';
  px?: string;
  py?: string;
  mx?: string;
  my?: string;
  md?: CSSObject;
  radius?: '0px' | '5px' | '10px' | '25px';
  minHeight?: '120px';
  shadow?: 'small-black';
  maxWidth?: string;
  maxHeight?: string;
} & Pick<CSSObject, 'border' | 'width' | 'height'>;

export const Card = styled.div<Props>(({ border, width, height, ..._ }) => ({
  borderRadius: _.radius ?? '25px',
  background: `var(--color-bg-${_.bg ?? 'gray'})`,
  boxShadow: _.shadow ? `var(--shadow-${_.shadow})` : 'none',
  paddingLeft: _.px,
  paddingRight: _.px,
  paddingTop: _.py,
  paddingBottom: _.py,
  marginLeft: _.mx,
  marginRight: _.mx,
  marginTop: _.my,
  marginBottom: _.my,
  border,
  width,
  height,
  position: 'relative',
  maxWidth: _.maxWidth,
  maxHeight: _.maxHeight,
  minHeight: _.minHeight,
  '@media (min-width: 630px)': _.md,
}));
