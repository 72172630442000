import React, { FC, memo, PropsWithChildren } from 'react';
import styled from 'styled-components';

import SideMenu from './SideMenu';

import { useUser } from '@apis/account/useUser';
import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { Grid } from '@components/layout/grid';
import { VStack } from '@components/layout/vstack';
import { Heading } from '@components/typographies/heading';
import { breakpoint } from '@constants/theme';
import { useDetectMedia } from '@hooks/useDetectMedia';

export const Mypage: FC<PropsWithChildren> = ({ children }) => {
  const user = useUser();
  const { md } = useDetectMedia();

  return (
    <VStack align="center" spacing="0px" py="1rem" px="20px" style={{ overflowX: 'hidden' }}>
      <Block borderRadius="10px" maxWidth="1040px" width="100%" bg="white">
        <MyPageTitleCard radius="10px">
          <Heading>
            {user?.lastName}
            {user?.firstName} 様のマイページ
          </Heading>
        </MyPageTitleCard>
        <Grid
          md={{
            gridTemplateColumns: '240px auto',
          }}
        >
          {md && (
            <Block width="240px">
              <SideMenu />
            </Block>
          )}
          {children}
          {!md && <SideMenu />}
        </Grid>
      </Block>
    </VStack>
  );
};

export default memo(Mypage);

const MyPageTitleCard = styled(Card)`
  width: 100%;
  margin-bottom: 1rem;
  background: var(--color-bg-skin);
  padding: 15px;

  @media screen and (min-width: ${breakpoint.md}px) {
    padding: 25px; 30px;
  }
`;
