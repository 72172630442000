import React, { FC } from 'react';

import { Button } from '@components/controls/button';
import { CheckBox } from '@components/controls/checkbox';
import { Block } from '@components/layout/block';
import { Center } from '@components/layout/center';
import { VStack } from '@components/layout/vstack';
import { Heading2 } from '@components/typographies/heading';
import { Span } from '@components/typographies/span';

export const LeaveReason: FC = () => (
  <Block width="100%" padding="30px 30px">
    <Heading2 fontSize="24px" lh="40px">
      退会する理由を教えてください。
      <Span fontSize="14px" fontWeight="400">
        *複数選択可
      </Span>
    </Heading2>
    <VStack spacing="30px" align="flex-start" py="30px">
      <CheckBox rounded id="1" placeholder="別のサイトでの購入が増えたから。" />
      <CheckBox rounded id="2" placeholder="サイトが使いにくいから。" />
      <CheckBox rounded id="3" placeholder="商品数が少ないから。" />
      <CheckBox rounded id="4" placeholder="値段が高いから。" />
      <CheckBox rounded id="5" placeholder="その他" />
    </VStack>
    <Center>
      <Button color="white" height="50px" width="232px" radius="5px">
        退会する
      </Button>
    </Center>
  </Block>
);
