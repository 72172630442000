import React, { memo } from 'react';

import { Block } from '@components/layout/block';
import { Card } from '@components/layout/card';
import { Link } from '@components/typographies/link';
import { SideMenuHeader, SideMenuItem } from '@features/mypage/SideMenu/style';

const SideMenu = () => {
  return (
    <Card
      bg="white"
      radius="0px"
      border="1px solid lightgray"
      width="100%"
      py="0px"
      px="0px"
      md={{
        borderRadius: '5px',
        width: '100%',
        padding: '10px',
        margin: '0px',
      }}
    >
      {/*   <Block
        width="100%"
        position="relative"
        borderBottom="1px solid lightgray"
        md={{ minHeight: '100px', paddingBottom: '20px' }}
      >
        <SideMenuHeader>お知らせ</SideMenuHeader>
        {[].map((news) => {
          return (
            <SideMenuItem as="a" bg="white" key={news.newsid} href="/mypage/news">
              {news.newsTitle}
            </SideMenuItem>
          );
        })}
        <Block
          bottom="0px"
          fontSize="12px"
          width="100%"
          padding="10px"
          justifyContent="end"
          alignItems="center"
          display="flex"
          md={{ position: 'absolute' }}
        >
          もっと見る
          <ArrowIcon />
        </Block>
      </Block>*/}
      <Block
        width="100%"
        position="relative"
        borderBottom="1px solid lightgray"
        md={{ minHeight: '100px' }}
      >
        <SideMenuHeader>購入</SideMenuHeader>
        <SideMenuItem bg="white">
          <Link to="/mypage/history" style={{ color: 'black' }}>
            購入履歴
          </Link>
        </SideMenuItem>
      </Block>
      {/* <Block width="100%" position="relative" borderBottom="1px solid lightgray" md={{ minHeight: '100px' }}>
        <SideMenuHeader>お気に入り</SideMenuHeader>
        <SideMenuItem bg="white" href={'/mypage/favorites/item/'}>
          お気に入り商品一覧
        </SideMenuItem>
        <SideMenuItem bg="white" href={'/mypage/favorites/shop/'}>
          お気に入りショップ一覧
        </SideMenuItem>
      </Block> */}
      <Block width="100%" minHeight="100px" position="relative" md={{ minHeight: '100px' }}>
        <SideMenuHeader>会員情報</SideMenuHeader>
        <SideMenuItem bg="white">
          <Link to="/mypage/basic-info" style={{ color: 'black' }}>
            会員情報の確認・変更
          </Link>
        </SideMenuItem>
        <SideMenuItem bg="white">
          <Link to="/mypage/reset" style={{ color: 'black' }}>
            パスワードの変更
          </Link>
        </SideMenuItem>
      </Block>
    </Card>
  );
};

export default memo(SideMenu);
