import styled, { CSSObject } from 'styled-components';

import { breakpoint } from '@constants/theme';

type Props = {
  bg?: 'orange' | 'white' | 'gray' | 'gray2' | 'skin';
  md?: CSSObject;
  sm?: CSSObject;
};

export const SideMenuItem = styled.div<Props>`
  background: var(--color-bg-${(_) => _.bg ?? 'white'});
  width: 100%;
  padding: 10px;
  font-size: 12px;
  margin-bottom: 0px;
  text-decoration: none;
  border-bottom: 1px solid lightgray;
  width: 100%;
  color: black;
  display: block;

  &:last-child {
    border-bottom: 0px solid lightgray;
  }

  @media screen and (min-width: ${breakpoint.md + 1}px) {
    padding: 0px;
    font-size: 14px;
    margin-bottom: 0.8rem;
    border-bottom: 0px;
  }
`;

export const SideMenuHeader = styled.h3`
  padding: 10px;
  font-size: 18px;
  margin-top: 0px;
  margin-bottom: 0rem;
  border-bottom: 1px solid lightgray;
  background: var(--color-bg-skin);

  @media screen and (min-width: ${breakpoint.md + 1}px) {
    background: white;
    padding: 0px;
    font-size: 22px;
    margin-top: 1.2rem;
    margin-bottom: 0.8rem;
    border-bottom: 0px;
  }
`;
